import { Button, Col, DatePicker, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setFiltroPt,
  startGetPTServicios,
  startGetXLSPTServicios,
} from "../../../context/actions/ptActions";
import { EstatusPTDropdown } from "../../../components/dropdown/EstatusPTDropdown";

const { RangePicker } = DatePicker;

export const Controles = () => {
  const [fechaAqui, setFechaAqui] = useState();
  const dispatch = useDispatch();
  const { estatusId, guia, refacturacion } = useSelector(
    (state) => state.ptReducer
  );

  const handleFechas = (val1, val2) => {
    dispatch(setFiltroPt(val2, "fechas"));
    setFechaAqui(val1);
  };

  return (
    <Row gutter={10} style={{ marginBottom: 5 }}>
      <Col span={8}></Col>
      <Col span={5}>
        <RangePicker
          placeholder={["Inicio", "Fin"]}
          format="YYYY-MM-DD"
          size="small"
          value={fechaAqui}
          onChange={handleFechas}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={4}>
        <EstatusPTDropdown
          value={estatusId}
          onChange={(val) => dispatch(setFiltroPt(val, "estatusId"))}
        />
      </Col>

      <Col span={4}>
        <Input
          value={guia}
          onChange={({ target }) => dispatch(setFiltroPt(target.value, "guia"))}
          size="small"
          placeholder="guia / siniestro"
        />
      </Col>
      <Col span={1}>
        <Button
          onClick={() => dispatch(startGetPTServicios())}
          size="small"
          danger
        >
          Filtrar
        </Button>
      </Col>
      <Col span={2}>
        <Button
          onClick={() => dispatch(startGetXLSPTServicios())}
          size="small"
          danger
        >
          Descargar XLS
        </Button>
      </Col>

    </Row>
  );
};
