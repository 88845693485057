export const returnPTStatus = (estatusId) => {
  let salida = "-";

  switch (estatusId) {
    case 0:
      salida = "Inicio";
      break;
    case 1:
      salida = "Contactando cliente";
      break;
    case 2:
      salida = "Recolectando docs";
      break;
    case 3:
      salida = "Validando docs";
      break;
    case 4:
      salida = "Solicitando guia";
      break;
    case 5:
      salida = "Confirmando guia";
      break;
    case 6:
      salida = "Entregando docs";
      break;
    case 7:
      salida = "Finalizado";
      break;

    default:
      break;
  }

  return salida;
};
