import { Table } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import useColums from "./useColums";
import { useNavigate } from "react-router";

export const Tabla = () => {
  const navigate = useNavigate();
  const { concesionariosServicios } = useSelector(
    (state) => state.concesionariosReducer
  );

  const { colums } = useColums();
  return (
    <Table
      size="small"
      rowKey="id"
      columns={colums}
      dataSource={concesionariosServicios}
      className="tabla"
      onRow={(record, rowIndex) => {
        return {
          onClick: () => navigate(`/ConsesionariosDetalle/${record.id}`),
        };
      }}
    />
  );
};
