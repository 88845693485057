import { Col, Row, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ComunicacionItem } from "./_ComunicacionItem";

export const Listado = () => {
  const [items, setItems] = useState([]);
  const { historial } = useSelector((state) => state.historialReducer);

  useEffect(() => {
    let temp = [];
    historial.map((h) => {
      temp = [
        ...temp,
        {
          color: "green",
          children: <ComunicacionItem historial={h} />,
        },
      ];
    });

    setItems(temp);
  }, [historial]);
  return <Timeline items={items} />;
};
