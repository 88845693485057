import React from "react";

import { RefacturaPdf } from "./_RefacturaPdf";
import { RefacturaXml } from "./_RefacturaXml";
import { useSelector } from "react-redux";
import { ModeloRefactura } from "./_ModeloRefactura";

export const RefacturacionDocs = () => {
  const { refacturacion } = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );

  if (!refacturacion) return <></>;
  return (
    <>
      <div className="subTitulo">Documentos de Refactura</div>
      <ModeloRefactura />
      <RefacturaPdf />
      <RefacturaXml />
    </>
  );
};
