import { Radio } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startLoadIncidenciasResponsable } from "../../../context/actions/ptActions";

///
export const Controles = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startLoadIncidenciasResponsable("Gnp"));
  }, []);

  const handleChange = ({ target }) =>
    dispatch(startLoadIncidenciasResponsable(target.value));

  ///
  return (
    <Radio.Group onChange={handleChange} defaultValue="Gnp">
      <Radio.Button value="Gnp">GNP</Radio.Button>
      <Radio.Button value="Escotel">Escotel</Radio.Button>
      <Radio.Button value="Asegurado">Asegurado</Radio.Button>
    </Radio.Group>
  );
};
