import { Route, Routes } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { Login } from '../views/login/Login';
import { ProtectedRoutes } from './ProtectedRoutes';
import Loading from '../components/loading/Loading';
import { consLogged } from '../constants/consLogged';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { verificaLogin } from '../context/actions/loginActions';
import { startGetStatusCat } from '../context/actions/concesionariosActions';


export const AppRouter = () => {
  const { logged } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(verificaLogin());
    dispatch(startGetStatusCat())
  }, [])

  if (logged === consLogged.STARTING) return <Loading />;


  return (
    <>
      <Routes>
        <Route path="login/*" element={
          
          <PublicRoute>
            <Routes>
              <Route path="/*" element={<Login />} />
            </Routes>
          </PublicRoute>
        }
        />

        <Route path="/*" element={
          <PrivateRoute>
            <ProtectedRoutes />
          </PrivateRoute>
        } />
      </Routes>

    </>
  )
}