import { Col, Row } from "antd";
import React from "react";
import { Elementos } from "./_Elementos";
import useSelection from "antd/es/table/hooks/useSelection";
import { useSelector } from "react-redux";

export const Tabla = () => {
  const { incidenciasAbiertas } = useSelector((state) => state.ptReducer);
  return (
    <div style={{ margin: 20 }}>
      <Row>
        <Col span={4} className="subTitulo">
          Folio
        </Col>
        <Col span={4} className="subTitulo">
          Siniestro
        </Col>
        <Col span={4} className="subTitulo">
          Poliza
        </Col>
        <Col span={4} className="subTitulo">
          Fecha
        </Col>
        <Col span={4} className="subTitulo">
          Incidencia
        </Col>
      </Row>

      {incidenciasAbiertas.map((a, i) => (
        <Elementos incidencia={a} key={i} />
      ))}

    </div>
  );
};
