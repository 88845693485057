import React from "react";
import { Row10 } from "../../../components/row/Row10";
import { Col_12_8_6_4 } from "../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../components/label/LabelValor";
import { Col_doble } from "../../../components/col/Col_doble";
import { Col } from "antd";
import { useSelector } from "react-redux";
import { FechaIngresoDestino } from "./inputs/_FechaIngresoDestino";
import { PersonaConfirmaIngreso } from "./inputs/_PersonaConfirmaIngreso";

export const Seccion3 = () => {
  const { concesionariosServicio: c } = useSelector(
    (state) => state.concesionariosReducer
  );

  return (
    <>
      <Row10>
        <FechaIngresoDestino />
        <PersonaConfirmaIngreso />
        <Col_12_8_6_4>
          <LabelValor label="Estado origen" valor={c.estadoOrigen} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Municipio origen" valor={c.municipioOrigen} />
        </Col_12_8_6_4>
        <Col_doble>
          <LabelValor label="Dirección origen" valor={c.direccionOrigen} />
        </Col_doble>
      </Row10>
      <Row10>
        <Col_12_8_6_4>
          <LabelValor label="Estado destino" valor={c.estadoDestino} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Municipio destino" valor={c.municipioDestino} />
        </Col_12_8_6_4>
        <Col_doble>
          <LabelValor label="Dirección destino" valor={c.direccionDestino} />
        </Col_doble>
        <Col_12_8_6_4>
          <LabelValor label="Tipo de destino" valor={c.tipoDestino} />
        </Col_12_8_6_4>
      </Row10>
      <Row10>
        <Col span={12}>
          <LabelValor
            label="Observaciones generales"
            valor={c.observacionesGenerales}
          />
        </Col>
        <Col_12_8_6_4>
          <LabelValor label="Crucero" valor={c.crucero} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Dias de pensión" valor={c.diasPension} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="Cuenta especial" valor={c.cuentaEspecial} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor label="En proceso jurídico" valor={c.enProcesoJuridico} />
        </Col_12_8_6_4>
        <Col_12_8_6_4>
          <LabelValor
            label="Documentos de liberación"
            valor={c.documentosLiberacion}
          />
        </Col_12_8_6_4>
      </Row10>
    </>
  );
};
