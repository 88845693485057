import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const EstatusDropdown = ({ value, onChange }) => {
  const [opciones, setOpciones] = useState([]);
  const { estatusCat } = useSelector((state) => state.concesionariosReducer);

  useEffect(() => {
    let temp = [{ label: "todos", value: null }];
    estatusCat.map((a) => {
      temp = [
        ...temp,
        {
          value: a.estatusId,
          label: a.nombre,
        },
      ];
      setOpciones(temp);
    });
  }, [estatusCat]);

  return (
    <Select
      size="small"
      style={{ width: "100%" }}
      value={value}
      onChange={onChange}
      options={opciones}
      placeholder="Estatus"
    />
  );
};
