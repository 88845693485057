import { Col, Row, Timeline } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HistorialItem } from "./HistorialItem";

export const Listado = () => {
  const [items, setItems] = useState([]);
  const { historial } = useSelector((state) => state.historialReducer);

  useEffect(() => {
    let temp = [];
    historial.map((h) => {
      temp = [
        ...temp,
        {
          color: "green",
          children: <HistorialItem historial={h} />,
        },
      ];
    });

    setItems(temp);
  }, [historial]);
  return (
    <div className="cajaHistorial">
      <Timeline items={items} />
    </div>
  );
};
