import React, { useState } from "react";
import { Col_12_8_6_4 } from "../../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../../components/label/LabelValor";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Modal } from "antd";
import { startUpdateServicio } from "../../../../context/actions/concesionariosActions";
import { useParams } from "react-router";
import { dateFromString } from "../../../../utils/dateFromString";

export const FechaIngresoDestino = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [date, setNewDate] = useState();
  const { concesionariosServicio: c } = useSelector(
    (state) => state.concesionariosReducer
  );

  const HandleOk = () => {
    dispatch(startUpdateServicio({ fechaIngresoDestino: date }, id));
    setOpen(false);
  };

  /////
  return (
    <>
      <Col_12_8_6_4>
        <LabelValor
          labelStyle={{ cursor: "pointer", textDecoration: "underline dotted" }}
          label="Fecha de ingreso a destino"
          valor={dateFromString(c.fechaIngresoDestino).soloFecha}
          onClick={() => setOpen(true)}
        />
      </Col_12_8_6_4>
      <Modal
        title="Establecer Fecha Ingreso Destino"
        onCancel={() => setOpen(false)}
        onOk={HandleOk}
        open={open}
      >
        <DatePicker
          style={{ width: "100%" }}
          onChange={(val1, val2) => setNewDate(val2)}
        />
      </Modal>
    </>
  );
};
