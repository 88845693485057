import { concesionariosReducer } from "../reducers/concesionariosReducer";
import { notificationReducer } from "../reducers/notificationReducer";
import { ptReducer } from "../reducers/ptReducer";
import { userReducer } from "../reducers/userReducer";
import { historialReducer } from "../reducers/historialReducer";
import { flujoRefacturaReducer } from "../reducers/flujoRefacturaReducer";

export const allReducers = {
  notificationReducer,
  userReducer,
  concesionariosReducer,
  ptReducer,
  historialReducer,
  flujoRefacturaReducer,
};
