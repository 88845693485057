import { useNavigate } from "react-router-dom";
import {
  BranchesOutlined,
  UnorderedListOutlined,
  CloudUploadOutlined,
  WarningOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLogoff } from "../context/actions/loginActions";

export const useMenuItems = () => {
  const { Modulo_concesionarios, Modulo_pt } = useSelector(
    (state) => state.userReducer.user
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openKeys, setOpenKeys] = useState([1]);
  const rootSubmenuKeys = [1, 2];

  const concesionadosItems = {
    label: "Concesionados",
    key: "1",
    icon: <BranchesOutlined />,
    children: [
      {
        label: "Servicios",
        key: "11",
        icon: <UnorderedListOutlined />,
      },
      {
        label: "Cargar Datos",
        key: "12",
        icon: <CloudUploadOutlined />,
      },
    ],
  };

  const ptItems = {
    label: "Perdida Total",
    key: "2",
    icon: <WarningOutlined />,
    children: [
      {
        label: "Servicios",
        key: "21",
        icon: <UnorderedListOutlined />,
      },
      {
        label: "Refactura",
        key: "22",
        icon: <UnorderedListOutlined />,
      },
      {
        label: "Incidencias",
        key: "23",
        icon: <UnorderedListOutlined />,
      },
      {
        label: "Cargar Datos",
        key: "24",
        icon: <CloudUploadOutlined />,
      },
    ],
  };

  let items = [
    {
      label: "Cerrar Sesión",
      key: "3",
      icon: <PoweroffOutlined />,
    },
  ];

  if (Modulo_pt) items = [ptItems, ...items];
  if (Modulo_concesionarios) items = [concesionadosItems, ...items];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) setOpenKeys(keys);
    else setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const goTo = ({ key }) => {
    if (key === "11") navigate("/ConsesionariosServicios");
    if (key === "12") navigate("/ConsesionariosCarga");

    if (key === "21") navigate("/PTServicios");
    if (key === "22") navigate("/ListaRefactura");
    if (key === "23") navigate("/ListaIncidencias");
    if (key === "24") navigate("/PTCarga");

    if (key === "3") dispatch(startLogoff());
  };
  return { openKeys, items, goTo, onOpenChange };
};
