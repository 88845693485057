import { Col, Row } from "antd";
import { dateFromString } from "../../../../utils/dateFromString";
import { FechaTermino } from "./_FechaTermino";

export const ListadoItem = ({
  reg: { id, motivo, fechaTermino, fechaInicio, usuarioCrea, usuarioCierra },
}) => {
  ////

  //////////////
  return (
    <div
      style={{
        marginBottom: 10,
        paddingBottom: 5,
        borderBottom: "1px solid #ccc",
      }}
    >
      <Row style={{ marginBottom: 5 }} className="" gutter={[10, 5]}>
        <Col span={24}>
          <b>Inicia:</b> {dateFromString(fechaInicio).dateStep} -{" "}
          <span style={{ color: "orange" }}>{usuarioCrea}</span>
        </Col>

        <Col span={24}>
          <FechaTermino
            fechaTermino={fechaTermino}
            registroId={id}
            usuarioCierra={usuarioCierra}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>{motivo} </Col>
      </Row>
    </div>
  );
};
