import { Button, Col, Row, Space, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Cabecera } from "./_Cabecera";
import {
  startGetDocumentosRefactura,
  startGetListaRefactura,
} from "../../../context/actions/flujoRefacturaActions";
import { TotalRegistros } from "./_TotalRegistros";
import {
  CheckCircleOutlined,
  CloudDownloadOutlined,
  DownloadOutlined,
  EyeFilled,
  EyeOutlined,
  SendOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { SkeletonPage } from "../../../layout/SkeletonPage";
import { CargarFileModal } from "./_CargarFileModal";
import { URL_PT } from "../../../constants/url";

export const ListaRefactura = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listaRefactura, cargando } = useSelector(
    (state) => state.flujoRefacturaReducer
  );

  useEffect(() => {
    dispatch(startGetListaRefactura(2)); // Lista para carga modelo refactura
  }, []);

  const handleModeloRefactura = (id) => {
    const body = {
      id,
      tipo: 1,
      nombre: `ModeloRefactura_${id}.pdf`,
    };

    console.log(body);
    dispatch(startGetDocumentosRefactura(body));
  };
  
  if (cargando) return <SkeletonPage />;

  return (
    <>
      <TotalRegistros total={listaRefactura.length} />
      <Cabecera />

      {listaRefactura.map((a, i) => (
        <Row key={i} className="rowItemElemento">
          <Col span={4}>{a.id}</Col>
          <Col span={4}>{a.numSiniestro}</Col>
          <Col span={4}>{a.numPoliza}</Col>
          <Col span={4}>{a.estatusId}</Col>
          <Col span={4}>
            <Space>
              <Button
                onClick={() => handleModeloRefactura(a.id)}
                size="small"
                danger
              >
                Ver Modelo
              </Button>

              {a.urlRefacturaPDF !== null ? (
                <div style={{ width: 60 }}>
                  <CheckCircleOutlined style={{ color: "green" }} /> PDF
                </div>
              ) : (
                <CargarFileModal
                  title={`Cargar Refactura PDF para el folio ${a.id}`}
                  tooltipTitle={`Cargar refactura PDF para ${a.id}`}
                  url={`${URL_PT}/UploadFilesRefactura/${a.id}/RefacturaPDF`}
                  recarga={() => dispatch(startGetListaRefactura(2, true))}
                  tituloBoton="PDF"
                />
              )}
              {a.urlRefacturaXML !== null ? (
                <div style={{ width: 60 }}>
                  <CheckCircleOutlined style={{ color: "green" }} /> XML
                </div>
              ) : (
                <CargarFileModal
                  title={`Cargar Refactura XML para el folio ${a.id}`}
                  tooltipTitle={`Cargar refactura XML para ${a.id}`}
                  url={`${URL_PT}/UploadFilesRefactura/${a.id}/RefacturaXML`}
                  recarga={() => dispatch(startGetListaRefactura(2, true))}
                  tituloBoton="XML"
                />
              )}

              <Button
                onClick={() => navigate(`/PTDetalle/${a.id}`)}
                size="small"
                danger
              >
                <Tooltip title="Abrir servicio">
                  <SendOutlined />
                </Tooltip>
              </Button>
            </Space>
          </Col>
        </Row>
      ))}
    </>
  );
};
