import { URL_CONCESIONARIOS, URL_INCIDENCIAS } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, getFile, post, postGetFile } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetStatusCat = () => {
  return async (dispatch) => {
    const url = `${URL_CONCESIONARIOS}/GetEstatusCat`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeEstatusCat(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetStatusCat", `${result.error}`)
        )
      );
    }
  };
};

const storeEstatusCat = (EstatusArray) => ({
  type: types.storeEstatusCat,
  payload: EstatusArray,
});

//////////

export const startGetConcesionariosServicios = () => {
  return async (dispatch, getState) => {
    const { filtro } = getState().concesionariosReducer;
    const url = `${URL_CONCESIONARIOS}/GetServiciosByFiltro`;

    console.log(filtro);
    if (!filtro.fechas) {
      console.log("vacio");
      return;
    }

    const result = await post(url, filtro);
    console.log(result);

    if (result.code === 0) {
      dispatch(storeConcesionariosServicios(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetServiciosByFiltro", `${result.error}`)
        )
      );
    }
  };
};

const storeConcesionariosServicios = (servicios) => ({
  type: types.storeConcesionariosServicios,
  payload: servicios,
});

////////////////

export const startGetXLS = () => {
  return async (dispatch, getState) => {
    const { filtro } = getState().concesionariosReducer;
    const url = `${URL_CONCESIONARIOS}/GetXls`;

    const result = await postGetFile(url, "registros.xlsx", filtro);

    if (result.code !== 0) {
      dispatch(
        notification(composeErrorNotification("startGetXLS", `${result.error}`))
      );
    }
  };
};
///////

export const startGetXLSCostos = () => {
  return async (dispatch, getState) => {
    const { filtro } = getState().concesionariosReducer;
    const url = `${URL_CONCESIONARIOS}/GetExcelDesgloseByServicio/`;

    const result = await getFile(url, "registros_costos.xlsx", filtro);

    if (result.code !== 0) {
      dispatch(
        notification(composeErrorNotification("startGetXLS", `${result.error}`))
      );
    }
  };
};
///////

export const setFiltroConcesionarios = (val, target) => ({
  type: types.setFiltroConcesionarios,
  payload: { [target]: val },
});
///////

export const startGetConcesionariosServicio = (id, sinCargar) => {
  return async (dispatch) => {
    if (!sinCargar) dispatch(setCargandoDetalle(true));

    const url = `${URL_CONCESIONARIOS}/GetServicioConcesionado/${id}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(
        storeConcesionariosServicio({
          ...result.data.servicio,
          costos: result.data.costos,
          documentos: result.data.documentos,
          incidencias: result.data.incidencias,
        })
      );
      dispatch(setCargandoDetalle(false));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetServiciosConcesionado",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeConcesionariosServicio = (servicios) => ({
  type: types.storeConcesionariosServicio,
  payload: servicios,
});

const setCargandoDetalle = (state) => ({
  type: types.setCargandoDetalle,
  payload: state,
});

//////

export const startUpdateEstatus = (body) => {
  return async (dispatch, getState) => {
    const final = {
      id: Number(body.id),
      estatusId: Number(body.estatusId),
      usuarioId: getState().userReducer.user.usuarioId,
    };

    console.log({ final });
    const url = `${URL_CONCESIONARIOS}/UpdateEstatus`;
    const result = await post(url, final);

    if (result.code === 0) {
      dispatch(startGetConcesionariosServicio(body.id, true));
      dispatch(notification(composeSuccessNotification("Estatus actualizado")));
    } else {
      dispatch(
        notification(
          composeErrorNotification("UpdateEstatus", `${result.error}`)
        )
      );
    }
  };
};

/////

export const startUpdateServicio = (body, id) => {
  return async (dispatch, getState) => {
    const url = `${URL_CONCESIONARIOS}/UpdateServicio/${id}`;
    const { usuarioId } = getState().userReducer.user;
    const result = await post(url, { ...body, usuarioId });
    console.log(body);
    if (result.code === 0) {
      dispatch(startGetConcesionariosServicio(id, true));
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("UpdateServicio", `${result.error}`)
        )
      );
    }
  };
};

/////

///////

export const startPostConcesionarioCosto = (body) => {
  return async (dispatch, getState) => {
    const url = `${URL_CONCESIONARIOS}/PostCostoConcesionario`;
    const { usuarioId } = getState().userReducer.user;
    const result = await post(url, { ...body, usuarioId });

    if (result.code === 0) {
      dispatch(
        startGetConcesionariosServicio(body.ServicioConcesionariosId, true)
      );
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("PostCostoConcesionario", `${result.error}`)
        )
      );
    }
  };
};

/////////

export const startUpdateCosto = (body) => {
  return async (dispatch, getState) => {
    const { usuarioId } = getState().userReducer.user;
    const url = `${URL_CONCESIONARIOS}/UpdateCostoAutorizado/${body.id}`;
    const result = await post(url, {...body, usuarioId});

    if (result.code === 0) {
      dispatch(
        startGetConcesionariosServicio(body.ServicioConcesionariosId, true)
      );
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("UpdateCostoAutorizado", `${result.error}`)
        )
      );
    }
  };
};

///////

export const startPostConcesionarioIncidencia = (body) => {
  return async (dispatch, getState) => {
    const { usuarioId } = getState().userReducer.user;

    const url = `${URL_INCIDENCIAS}/CrearIncidenciaConcesionarios`;
    const result = await post(url, { ...body, usuarioId });

    if (result.code === 0) {
      dispatch(
        startGetConcesionariosServicio(body.ServicioConcesionarioId, true)
      );
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("CrearIncidencia", `${result.error}`)
        )
      );
    }
  };
};

///////////////

export const startTerminarIncidencia = (registroId, id) => {
  return async (dispatch, getState) => {
    const { usuarioId } = getState().userReducer.user;

    const url = `${URL_INCIDENCIAS}/TerminarIncidenciaConcesionarios/${registroId}/${usuarioId}`;
    const result = await get(url);
    console.log(url);
    if (result.code === 0) {
      dispatch(startGetConcesionariosServicio(id, true));
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("TerminarIncidencia", `${result.error}`)
        )
      );
    }
  };
};

//////////

export const startDeleteCosto = (body) => {
  return async (dispatch, getState) => {
    const url = `${URL_CONCESIONARIOS}/DeleteCostoConcesionarios/${body.id}`;
    const result = await get(url, body);
    console.log(body);
    if (result.code === 0) {
      dispatch(
        startGetConcesionariosServicio(body.ServicioConcesionariosId, true)
      );
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "DeleteCostoConcesionarios",
            `${result.error}`
          )
        )
      );
    }
  };
};
