import { Skeleton } from "antd";
import React from "react";

export const SkeletonPage = () => {
  return (
    <div>
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
      <Skeleton active />
    </div>
  );
};
