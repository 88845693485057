import { Alert, Button, Input, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startUpdateServicioPT } from "../../../../context/actions/ptActions";
import { useParams } from "react-router";

export const AnuladoGnp = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState();
  const [motivo, setMotivo] = useState();
  const { anuladoGNP, motivoAnulado } = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );

  const handleOk = () =>
    dispatch(
      startUpdateServicioPT({
        anuladoGNP: true,
        motivoAnulado: motivo,
        id,
      })
    );
  /////////
  if (anuladoGNP === true)
    return (
      <Alert
        style={{ marginTop: 20 }}
        message={`Anulado GNP: ${motivoAnulado}`}
        type="error"
      />
    );

  return (
    <div style={{ marginTop: 20 }}>
      <Button onClick={() => setShow(true)} size="small" danger>
        Anular GNP
      </Button>
      <Modal
        onOk={handleOk}
        onCancel={() => setShow(false)}
        open={show}
        title="Motivo de la Anulación"
      >
        <Input.TextArea onChange={({ target }) => setMotivo(target.value)} />
      </Modal>
    </div>
  );
};
