import React, { useEffect } from "react";
import CardPage from "../../../layout/CardPage";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { startGetConcesionariosServicio } from "../../../context/actions/concesionariosActions";
import { Detalle } from "./_Detalle";
import { startGetHistorial } from "../../../context/actions/historialActions";

export const ConsesionariosDetalle = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(startGetConcesionariosServicio(id));
    dispatch(startGetHistorial(id, 1));
  }, []);

  const handleRegresar = () => navigate("/ConsesionariosServicios");
  return (
    <CardPage
      titulo="Servicio Concesionado"
      tituloBoton="regresar"
      accionBoton={handleRegresar}
    >
      <Detalle />
    </CardPage>
  );
};
