import { CloudUploadOutlined } from "@ant-design/icons";
import React from "react";
import { message, Upload } from "antd";
import { URL_CONCESIONARIOS } from "../../../constants/url";

const { Dragger } = Upload;

export const FormaCarga = () => {
  const props = {
    name: "file",
    multiple: false,
    action: `${URL_CONCESIONARIOS}/LeerXLS`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} Archivo cargado correctamente`);
      } else if (status === "error") {
        message.error(`${info.file.name} Error al subir el archivo`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined />
      </p>
      <p className="ant-upload-text">Click o arrastra algun archivo</p>
      <p className="ant-upload-hint">
        Solamente soporta un solo archivo y de formato Excel
      </p>
    </Dragger>
  );
};
