import React from "react";
import { Controles } from "./_Controles";
import { Tabla } from "./_Tabla";
import CardPage from "../../../layout/CardPage";

export const ListaIncidencias = () => {
  
  
  return (
    <CardPage titulo="Incidencias Abiertas">
      <Controles />
      <Tabla />
    </CardPage>
  );
};
