import { types } from "../types/types";

const init = {
  ptServicios: [],
  ptDetalle: { servicioPT: {}, documentos: [], incidencias: [] },
  filtro: {
    fechas: ["2023-01-01", "2023-12-31"],
  },
  historial: [],
  filtroRefactura: "1",
  incidenciasAbiertas: [],
  listadoRefactura: [],
};
export const ptReducer = (state = init, action) => {
  //
  switch (action.type) {
    case types.storeListadoRefactura:
      return {
        ...state,
        listadoRefactura: action.payload,
      };

    case types.storeIncidenciasAbiertas:
      return {
        ...state,
        incidenciasAbiertas: action.payload,
      };

    case types.setFiltroRefactura:
      return {
        ...state,
        filtroRefactura: action.payload,
      };

    case types.storePtDetalle:
      return {
        ...state,
        ptDetalle: action.payload,
      };

    case types.storePtServicios:
      return {
        ...state,
        ptServicios: action.payload,
      };

    case types.setFiltroPt:
      return {
        ...state,
        filtro: { ...state.filtro, ...action.payload },
      };

    default:
      return state;
  }
};
