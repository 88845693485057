import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { startGetPTServicio } from "../../../../../context/actions/ptActions";
import { URL_PT } from "../../../../../constants/url";
import { useParams } from "react-router";
import { UploadSinNombre } from "../../../../../components/upload/UploadSinNombre";
import { useGetDocumentoRefcatura } from "../useGetDocumentoRefcatura";
import { CargarFileModal } from "../../../ListaRefactura/_CargarFileModal";
import { startGetListaRefactura } from "../../../../../context/actions/flujoRefacturaActions";

export const ModeloRefactura = () => {
  const { getFile } = useGetDocumentoRefcatura();
  const dispatch = useDispatch();
  const { id } = useParams();

  const url = `${URL_PT}/UploadFiles/${id}`;

  const { urlModeloRefactura } = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );
  //

  if (!urlModeloRefactura)
    return (
      <div style={{ margin: 20 }}>
        <CargarFileModal
          title={`Cargar Modelo de Refactura para el folio ${id}`}
          tooltipTitle={`Cargar modelo de refactura para el folio ${id}`}
          url={`${URL_PT}/UploadFilesRefactura/${id}/ModeloRefactura`}
          recarga={() => dispatch(startGetPTServicio(id))}
          tituloBoton="Modelo"
        />
      </div>
    );
  return (
    <>
      <div
        style={{ marginTop: 10 }}
        onClick={() => getFile(id, 1, `ModeloRefactura_${id}.pdf`)}
        className="rowItem"
      >
        Modelo Refactura
      </div>
    </>
  );
};
