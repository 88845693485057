import { types } from "../types/types";

const init = {
  concesionariosServicios: [],
  concesionariosServicio: {
    costos: [],
  },
  cargandoDetalle: false,
  estatusCat: [],
  otroArreglo: [],
  filtro: {
    fechas: ["2023-01-01", "2023-12-31"],
  },
  historial: [],
};

export const concesionariosReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeConcesionarioHistorial:
      return {
        ...state,
        historial: action.payload,
      };
    case types.setFiltroConcesionarios:
      return {
        ...state,
        filtro: { ...state.filtro, ...action.payload },
      };
    case types.storeOtroArreglo:
      return {
        ...state,
        otroArreglo: action.payload,
      };

    case types.storeEstatusCat:
      return {
        ...state,
        estatusCat: action.payload,
      };

    case types.setCargandoDetalle:
      return {
        ...state,
        cargandoDetalle: action.payload,
      };

    case types.storeConcesionariosServicio:
      return {
        ...state,
        concesionariosServicio: action.payload,
      };

    case types.storeConcesionariosServicios:
      return {
        ...state,
        concesionariosServicios: action.payload,
      };

    default:
      return state;
  }
};
