import React from "react";
import CardPage from "../../../layout/CardPage";
import { FormaCarga } from "./_FormaCarga";

export const ConsesionariosCarga = () => {
  return (
    <CardPage titulo="Carga de XLS: Concesionado">
      <FormaCarga />
    </CardPage>
  );
};
