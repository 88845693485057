import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startGetPTServicio } from "../../../../../context/actions/ptActions";
import { UploadSinNombre } from "../../../../../components/upload/UploadSinNombre";
import { useGetDocumentoRefcatura } from "../useGetDocumentoRefcatura";
import { startGetListaRefactura } from "../../../../../context/actions/flujoRefacturaActions";
import { CargarFileModal } from "../../../ListaRefactura/_CargarFileModal";
import { URL_PT } from "../../../../../constants/url";

export const RefacturaPdf = () => {
  const { getFile } = useGetDocumentoRefcatura();

  const dispatch = useDispatch();
  const { id } = useParams();

  const { urlRefacturaPDF } = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );

  /////////

  if (!urlRefacturaPDF)
    return (
      <div style={{ margin: 20 }}>
        <CargarFileModal
          title={`Cargar Refactura PDF para el folio ${id}`}
          tooltipTitle={`Cargar refactura PDF para el folio ${id}`}
          url={`${URL_PT}/UploadFilesRefactura/${id}/RefacturaPDF`}
          recarga={() => dispatch(startGetPTServicio(id))}
          tituloBoton="PDF"
        />
      </div>
    );

  return (
    <>
      <div
        style={{ marginTop: 10 }}
        onClick={() => getFile(id, 2, `RefacturaPDF_${id}.pdf`)}
        className="rowItem"
      >
        Refactura PDF
      </div>
    </>
  );
};
