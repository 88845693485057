import { Radio } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setFiltroRefactura,
  startGetByFiltroRefactura,
} from "../../../context/actions/ptActions";
import { startGetListaRefactura } from "../../../context/actions/flujoRefacturaActions";

///
export const Controles = () => {
  const dispatch = useDispatch();

  //
  const handleChange = ({ target }) => {
    dispatch(setFiltroRefactura(target.value));
    dispatch(startGetListaRefactura(target.value));
  };

  useEffect(() => {
    dispatch(setFiltroRefactura(1));
    dispatch(startGetListaRefactura(1));
  }, []);

  ///
  return (
    <div style={{}}>
      <Radio.Group danger defaultValue="1" onChange={handleChange}>
        <Radio.Button value="1">Carga Modelo Refactura</Radio.Button>
        <Radio.Button value="2">Carga Refactura</Radio.Button>
        <Radio.Button value="3">VoBo Refactura</Radio.Button>
      </Radio.Group>
    </div>
  );
};
