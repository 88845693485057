import { Col, Row } from "antd";
import React from "react";
import { dateFromString } from "../../../utils/dateFromString";
import { useNavigate } from "react-router";

export const Elementos = ({ incidencia }) => {
  const navigate = useNavigate();
  const handleClick = () =>
    navigate(`/PTDetalle/${incidencia.incidencia.servicioPTId}`);
  //
  return (
    <Row
      onClick={handleClick}
      className="rowItemElemento"
      style={{ cursor: "pointer" }}
    >
      <Col span={4}>{incidencia.incidencia.servicioPTId}</Col>
      <Col span={4}>{incidencia.numSiniestro}</Col>
      <Col span={4}></Col>
      <Col span={4}>
        {dateFromString(incidencia.incidencia.fechaInicio).soloFecha}
      </Col>
      <Col span={8}>{incidencia.incidencia.motivo}</Col>
    </Row>
  );
};
