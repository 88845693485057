import React, { useEffect } from "react";
import { Tabla } from "./_Tabla";
import CardPage from "../../../layout/CardPage";
import { Controles } from "./_Controles";
import { useDispatch } from "react-redux";
import { startGetPTServicios } from "../../../context/actions/ptActions";

export const PTservicios = () => {
  const dispatch = useDispatch();

  //
  useEffect(() => {
    dispatch(startGetPTServicios());
  });

  //
  return (
    <CardPage titulo="Servicios de Pérdida Total">
      <Controles />
      <Tabla />
    </CardPage>
  );
};
