import {
  URL_CONCESIONARIOS,
  URL_FLUJO_REFACTURA_PT,
  URL_HISTORIAL,
  URL_INCIDENCIAS,
  URL_PT,
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, getFile, post, postGetFile } from "../../utils/fetch";
import { types } from "../types/types";

import { notification } from "./notificationActions";

export const startGetListaRefactura = (tipo, noSkeleton) => {
  return async (dispatch) => {
    if (!noSkeleton) dispatch(setCargando(true));
    const url = `${URL_FLUJO_REFACTURA_PT}/GetListaRefactura/${tipo}`;

    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeListaRefactura(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetListaRefactura", `${result.error}`)
        )
      );
    }
    dispatch(setCargando(false));
  };
};

const storeListaRefactura = (payload) => ({
  type: types.storeListaRefactura,
  payload,
});

const setCargando = (estado) => ({
  type: types.setCargando,
  payload: estado,
});

////////

export const startGetDocumentosRefactura = (body) => {
  return async (dispatch, getState) => {
    const url = `${URL_PT}/GetDocumentosRefactura/${body.id}/${body.tipo}`;

    const result = await getFile(url, body.nombre);

    if (result.code !== 0) {
      dispatch(
        notification(
          composeErrorNotification("GetDocumentosRefactura", `${result.error}`)
        )
      );
    }
  };
};
