import { URL_HISTORIAL } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

/////////////////
export const startPostHistorial = (body) => {
  return async (dispatch, getState) => {
    const { usuarioId } = getState().userReducer.user;
    const url = `${URL_HISTORIAL}/PostHistorial`;
    const result = await post(url, { ...body, usuarioId });
    if (result.code === 0) {
      dispatch(startGetHistorial(body.IdServicio, body.tipoServicio));
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("PostHistorial", `${result.error}`)
        )
      );
    }
  };
};

export const startGetHistorial = (id, tipo) => {
  return async (dispatch) => {
    const url = `${URL_HISTORIAL}/GetHistorialByServicio/${id}/${tipo}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeHistorial(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetHistorialByServicio", `${result.error}`)
        )
      );
    }
  };
};

const storeHistorial = (historial) => ({
  type: types.storeHistorial,
  payload: historial,
});
