import { Button, Col, Input, InputNumber, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  startPostConcesionarioCosto,
  startPostConcesionarioIncidencia,
} from "../../../../../context/actions/concesionariosActions";
import { MotivoIncidenciaDropdown } from "../../../../../components/dropdown/MotivoIncidenciaDropdown";

export const Forma = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [motivo, setMotivo] = useState();
  //

  const handleClick = () => {
    dispatch(
      startPostConcesionarioIncidencia({
        motivo,
        ServicioConcesionarioId: Number(id),
      })
    );
    setMotivo();
  };

  ///////////////////
  return (
    <Row gutter={10}>
      <Col span={22}>
        <MotivoIncidenciaDropdown
          onChange={(val) => setMotivo(val)}
          value={motivo}
        />
      </Col>
      <Col span={2}>
        <Button onClick={handleClick} size="small" danger>
          OK
        </Button>
      </Col>
    </Row>
  );
};
