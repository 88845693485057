import React from "react";
import { Controles } from "./_Controles";
import { Tabla } from "./_Tabla";
import CardPage from "../../../layout/CardPage";

export const ListaRefactura = () => {
  return (
    <CardPage titulo="Listado Refactura">
      <Controles />
      <Tabla />
    </CardPage>
  );
};
