import { SyncOutlined, DeleteOutlined } from "@ant-design/icons";
import { Col, Input, InputNumber, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import {
  startDeleteCosto,
  startUpdateCosto,
} from "../../../../../context/actions/concesionariosActions";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";

export const ListadoItem = ({
  reg: { id, descripcion, costoInicial, costoAutorizado },
}) => {
  ////
  const dispatch = useDispatch();
  const [dato, setDato] = useState();
  const { id: ServicioConcesionariosId } = useParams();

  useEffect(() => {
    setDato(costoAutorizado);
  }, [costoAutorizado]);

  const handleClick = () =>
    dispatch(
      startUpdateCosto({ costoAutorizado: dato, id, ServicioConcesionariosId })
    );

  const handleDelete = () =>
    dispatch(startDeleteCosto({ id, ServicioConcesionariosId }));
  //////////////
  return (
    <Row className="rowItemElemento" gutter={10}>
      <Col span={11}>{descripcion}</Col>
      <Col span={6} style={{ textAlign: "right" }}>
        {formatCurrency(costoInicial)}
      </Col>
      <Col span={5} style={{ textAlign: "center", fontWeight: 600 }}>
        <InputNumber
          style={{ width: 60 }}
          size="small"
          value={dato}
          onChange={(val) => setDato(val)}
        />
      </Col>
      <Col span={2}>
        <Space>
          <SyncOutlined onClick={handleClick} className="botonIcono" />
          <DeleteOutlined onClick={handleDelete} className="botonIcono" />
        </Space>
      </Col>
    </Row>
  );
};
