import React from "react";
import { Forma } from "./_Forma";
import { Listado } from "./_Listado";
import { Divider } from "antd";

export const DetalleComunicacion = () => {
  return (
    <>
      <Forma />
      <Divider />
      <Listado />
    </>
  );
};
