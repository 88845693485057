import React from "react";
import { Col_12_8_6_4 } from "../../../../components/col/Col_12_8_6_4";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startUpdateServicioPT } from "../../../../context/actions/ptActions";
import { Radio } from "antd";

const opciones = ["Si", "No"];

export const Refacturar = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { servicioPT: c } = useSelector((state) => state.ptReducer.ptDetalle);

  const getValue = () => {
    if (c.refacturacion) return "Si";
    return "No";
  };

  const handleChange = ({ target }) => {
    const refacturacion = target.value === "Si" ? true : false;
    dispatch(startUpdateServicioPT({ refacturacion, id }));
  };

  ////
  return (
    <>
      <Col_12_8_6_4>
        <div className="label">Refacturación</div>
        <Radio.Group
          size="small"
          optionType="button"
          buttonStyle="outline"
          options={opciones}
          onChange={handleChange}
          value={getValue()}
        />
      </Col_12_8_6_4>
    </>
  );
};
