import { Col, Row } from "antd";
import React from "react";
import { ListaRefactura } from "./_ListaRefactura";
import { ListaVoBo } from "./_ListaVoBo";
import { useSelector } from "react-redux";
import { ListaModeloRefactura } from "./_ListaModeloRefactura";

export const Tabla = () => {
  const { filtroRefactura } = useSelector((state) => state.ptReducer);
  return (
    <div style={{ marginTop: 20 }} align={"center"}>
      {filtroRefactura == "1" && <ListaModeloRefactura />}
      {filtroRefactura == "2" && <ListaRefactura />}
      {filtroRefactura == "3" && <ListaVoBo />}
    </div>
  );
};
