import { useSelector } from "react-redux";
import { SkeletonPage } from "../../../layout/SkeletonPage";
import { Divider } from "antd";
import { Controles } from "./controles/Controles";
import { Timeline } from "./timeline/Timeline";
import { Seccion1 } from "./_Seccion1";
import { Seccion2 } from "./_Seccion2";
import { Seccion3 } from "./_Seccion3";

export const Detalle = () => {
  const { cargandoDetalle } = useSelector(
    (state) => state.concesionariosReducer
  );

  if (cargandoDetalle) return <SkeletonPage />;

  return (
    <>
      <Seccion1 />
      <Divider />
      <Timeline />
      <Divider />
      <Seccion2 />

      <Divider />
      <Controles />
      <Divider />

      <Seccion3 />
    </>
  );
};
