import React from "react";
import CardPage from "../../../layout/CardPage";
import { FormaCarga } from "./_FormaCarga";

export const PTCarga = () => {
  return (
    <CardPage titulo="Carga de XLS: Perdida Total">
      <FormaCarga />
    </CardPage>
  );
};
