import { Button, Col, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { startPostPTIncidencia } from "../../../../context/actions/ptActions";
import { ResponsableIncidenciaPTDropdown } from "../../../../components/dropdown/ResponsableIncidenciaPTDropdown";

export const Forma = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [motivo, setMotivo] = useState();
  const [responsable, setResponsable] = useState();

  //

  const deshabilitado = () => !motivo || !responsable;

  const handleClick = () => {
    dispatch(
      startPostPTIncidencia({
        motivo,
        responsable,
        ServicioPTId: Number(id),
      })
    );
    setMotivo();
    setResponsable();
  };

  ///////////////////
  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <ResponsableIncidenciaPTDropdown
          onChange={(val) => setResponsable(val)}
          value={responsable}
        />
      </Col>
      <Col span={24}>
        <Input
          onChange={({ target }) => setMotivo(target.value)}
          size="small"
          name="motivo"
          value={motivo}
        />
      </Col>
      <Col span={24}>
        <Button
          disabled={deshabilitado()}
          block
          onClick={handleClick}
          size="small"
          danger
        >
          Agregar Incidencia
        </Button>
      </Col>
    </Row>
  );
};
