import { consLogged } from "../../constants/consLogged";
import { types } from "../types/types";

const init = {
  logged: consLogged.STARTING,
  user: { },
  message: "",
  loginErr: ""
};
export const userReducer = (state = init, action) => {
  switch (action.type) {
    
    case types.setLoginErr:
      return {
        ...state,
        loginErr: action.payload,
      };

    case types.storeUserData:
      return {
        ...state,
        user: action.payload,
      };

    case types.setMessage:
      return {
        ...state,
        message: action.payload,
      };

    case types.setLogged:
      return {
        ...state,
        logged: action.payload,
      };

    default:
      return state;
  }
};
