import { types } from "../types/types";

const init = {
  historial: [],
};

export const historialReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeHistorial:
      return {
        ...state,
        historial: action.payload,
      };

    default:
      return state;
  }
};
