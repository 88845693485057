import { EstatusPill } from "../../../components/estatusPill/EstatusPill";
import { cortaString } from "../../../utils/cortaString";
import { dateFromString } from "../../../utils/dateFromString";
import { Tache } from "./_Tache";

////
export default function useColums() {
  const colums = [
    {
      title: "Folio",
      dataIndex: "value",
    },
    {
     title: "No. Siniestro" ,
      dataIndex: "siniestro",
    },
    {
      title: "fecha",
      dataIndex: "fechaIncio",
      render: (dato, renglon) => dateFromString(dato).soloFecha,
    },

    {
      title: "Titular",
      dataIndex: "titularPoliza",
      render: (val, row) => cortaString(val, 25),
    },
    {
      title: "proveedor",
      dataIndex: "proveedorConcesionado",
      render: (val, row) => cortaString(val, 25),
    },
    {
      title: "Origen",
      dataIndex: "estadoOrigen",
    },
    {
      title: "Incidencia",
      dataIndex: "incidencia",
      render: (dato, renglon) => (
        <div style={{ textAlign: "center" }}>
          {renglon?.totalIncidenciasAbiertas || 0} /{" "}
          {renglon?.totalIncidencias || 0}
        </div>
      ),
    },
    {
      title: "Req Auth",
      dataIndex: "requiereAutorizacion",
      render: (dato, renglon) => <Tache dato={dato} />,
    },

    {
      title: "Anulado",
      dataIndex: "anuladoGNP",
      render: (dato, renglon) => <Tache dato={dato} />,
    },
    {
      title: "Arrastre Escotel",
      dataIndex: "requiere2doArrastre",
      render: (dato, renglon) => <Tache dato={dato} />,
    },

    {
      title: "Estatus",
      dataIndex: "estatusNombre",
      render: (dato, renglon) => <EstatusPill renglon={renglon} />,
    },
  ];

  return { colums };
}
