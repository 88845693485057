import { Navigate, Route, Routes } from "react-router-dom";
import { Home } from "../views/home/Home";

import { LayoutPage } from "../layout/LayoutPage";
import { ConsesionariosServicios } from "../views/serviciosConsesionarios/consesionariosServicios/ConsesionariosServicios";
import { ConsesionariosDetalle } from "../views/serviciosConsesionarios/consesionariosDetalle/ConsesionariosDetalle";
import { ConsesionariosCarga } from "../views/serviciosConsesionarios/consesionariosCarga/ConsesionariosCarga";
import { PTCarga } from "../views/serviciosPT/PTCarga/PTCarga";
import { PTDetalle } from "../views/serviciosPT/PTDetalle/PTDetalle";
import { PTservicios } from "../views/serviciosPT/PTservicios/Servicios";
import { ListaIncidencias } from "../views/serviciosPT/ListaIncidencias/ListaIncidencias";
import { ListaRefactura } from "../views/serviciosPT/ListaRefactura/ListaRefactura";

export const ProtectedRoutes = () => {
  return (
    <>
      <LayoutPage>
        <Routes>
          <Route path="home" element={<Home />} />
          <Route path="/" element={<Navigate to="/home" />} />

          <Route
            path="/ConsesionariosServicios"
            element={<ConsesionariosServicios />}
          />
          <Route
            path="/ConsesionariosDetalle/:id"
            element={<ConsesionariosDetalle />}
          />
          <Route
            path="/ConsesionariosCarga"
            element={<ConsesionariosCarga />}
          />

          <Route path="/PTservicios" element={<PTservicios />} />
          <Route path="/PTDetalle/:id" element={<PTDetalle />} />
          <Route path="/PTCarga" element={<PTCarga />} />

          <Route path="/ListaRefactura" element={<ListaRefactura />} />
          <Route path="/ListaIncidencias" element={<ListaIncidencias />} />
        </Routes>
      </LayoutPage>
    </>
  );
};
