import React from "react";

export const TotalRegistros = ({ total }) => {
  return (
    <div
     className="TotalRegistros"
    >
      Registros: {total}
    </div>
  );
};
