import React from "react";
import { startGetDocumentosRefactura } from "../../../../context/actions/flujoRefacturaActions";
import { useDispatch } from "react-redux";

export const useGetDocumentoRefcatura = () => {
  const dispatch = useDispatch();
  const getFile = (id, tipo, nombre) => {
    const body = {
      id,
      tipo, // 1=Modelo, 2=pdf, 3=Xml
      nombre,
    };
    dispatch(startGetDocumentosRefactura(body));
  };

  return { getFile };
};
