import React from "react";
import { Forma } from "./_Forma";
import { Listado } from "./_Listado";


export const Documentos = () => {
  return (
    <>
      <Forma />
      <Listado />
    </>
  );
};
