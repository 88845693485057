import React from "react";

export default function LabelValor({
  label,
  valor,
  valorStyle,
  onClick,
  labelStyle,
}) {
  return (
    <>
      <div style={{ ...labelStyle }} onClick={onClick} className="label">
        {label}
      </div>
      <div className="valor" style={{ ...valorStyle }}>
        {valor}
      </div>
    </>
  );
}
