import { ClockCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dateFromString } from "../../../../../utils/dateFromString";
import { DatePicker, Modal } from "antd";
import { useParams } from "react-router";
import { handleDate } from "../../../../../utils/dandleDate";
import locale from "antd/es/date-picker/locale/es_ES";
import { startUpdateServicioPT } from "../../../../../context/actions/ptActions";

export const Inicio = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [newDate, setNewDate] = useState();
  const { fechaInicio } = useSelector(
    (state) => state.ptReducer.ptDetalle.servicioPT
  );

  const changeDate = () => {
    const body = {
      fechaInicio: newDate,
      id,
    };
    dispatch(startUpdateServicioPT(body));
    setOpenModal(false);
  };

  ////
  return (
    <div>
      <ClockCircleOutlined />
      <span
        style={{ cursor: "pointer", paddingLeft: 3 }}
        onClick={() => setOpenModal(true)}
      >
        {dateFromString(fechaInicio).dateStep}
      </span>
      <Modal
        open={openModal}
        title="Cambiar fecha"
        onCancel={() => setOpenModal(false)}
        onOk={changeDate}
      >
        <DatePicker
          showTime
          locale={locale}
          style={{ marginTop: 20 }}
          onChange={(val, strVal) => setNewDate(handleDate(val))}
        />
      </Modal>
    </div>
  );
};
