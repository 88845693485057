import { types } from "../types/types";

const init = {
  listaRefactura: [],
  cargando: false,
};

export const flujoRefacturaReducer = (state = init, action) => {
  switch (action.type) {
    case types.setCargando:
      return {
        ...state,
        cargando: action.payload,
      };

    case types.storeListaRefactura:
      return {
        ...state,
        listaRefactura: action.payload,
      };

    default:
      return state;
  }
};
