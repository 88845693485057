import {
  URL_CONCESIONARIOS,
  URL_HISTORIAL,
  URL_INCIDENCIAS,
  URL_PT,
} from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post, postGetFile } from "../../utils/fetch";
import { types } from "../types/types";
import { startGetHistorial } from "./historialActions";
import { notification } from "./notificationActions";

export const startGetPTServicios = () => {
  return async (dispatch, getState) => {
    const { filtro } = getState().ptReducer;
    const url = `${URL_PT}/GetServiciosByFiltro`;

    const result = await post(url, filtro);

    if (result.code === 0) {
      dispatch(storePtServicios(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetServiciosByFiltro", `${result.error}`)
        )
      );
    }
  };
};

const storePtServicios = (servicios) => ({
  type: types.storePtServicios,
  payload: servicios,
});

///////////
export const startGetXLSPTServicios = () => {
  return async (dispatch, getState) => {
    const { filtro } = getState().ptReducer;
    const url = `${URL_PT}/GetXls`;

    const result = await postGetFile(url, "registros_pt.xlsx", filtro);

    if (result.code !== 0) {
      dispatch(
        notification(composeErrorNotification("startGetXLS", `${result.error}`))
      );
    }
  };
};
///////

///////////

export const startGetPTServicio = (id) => {
  return async (dispatch, getState) => {
    const { filtro } = getState().ptReducer;
    const url = `${URL_PT}/GetServicioPT/${id}`;

    const result = await get(url, filtro);

    if (result.code === 0) {
      dispatch(storePtDetalle(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetServicioPT", `${result.error}`)
        )
      );
    }
  };
};

const storePtDetalle = (servicios) => ({
  type: types.storePtDetalle,
  payload: servicios,
});

/////
export const setFiltroPt = (val, target) => ({
  type: types.setFiltroPt,
  payload: { [target]: val },
});

///////

export const startUpdateEstatus = (body) => {
  return async (dispatch, getState) => {
    const final = {
      servicioPTId: body.id,
      estatusId: Number(body.estatusId),
      usuarioId: getState().userReducer.user.usuarioId,
    };

    console.log({ final });
    const url = `${URL_PT}/UpdateEstatus`;
    const result = await post(url, final);

    if (result.code === 0) {
      dispatch(startGetPTServicio(body.id, true));
      dispatch(startGetHistorial(body.id, 2));
      dispatch(notification(composeSuccessNotification("Estatus actualizado")));
    } else {
      dispatch(
        notification(
          composeErrorNotification("UpdateEstatus", `${result.error}`)
        )
      );
    }
  };
};

////

export const startUpdateServicioPT = (body) => {
  return async (dispatch, getState) => {
    const final = {
      usuarioId: getState().userReducer.user.usuarioId,
      ...body,
    };

    const url = `${URL_PT}/UpdateServicio/${body.id}`;

    const result = await post(url, final);

    if (result.code === 0) {
      dispatch(startGetPTServicio(body.id, true));
      dispatch(startGetHistorial(body.id, 2));
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("UpdateServicio", `${result.error}`)
        )
      );
    }
  };
};

////////////

export const startPostPTIncidencia = (body) => {
  return async (dispatch, getState) => {
    const { usuarioId } = getState().userReducer.user;

    const url = `${URL_INCIDENCIAS}/CrearIncidenciaPT`;
    const result = await post(url, { ...body, usuarioId });

    if (result.code === 0) {
      dispatch(startGetPTServicio(body.ServicioPTId, true));
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("CrearIncidenciaPT", `${result.error}`)
        )
      );
    }
  };
};

///////////////

export const startTerminarIncidenciaPT = (registroId, id) => {
  return async (dispatch, getState) => {
    const { usuarioId } = getState().userReducer.user;

    const url = `${URL_INCIDENCIAS}/TerminarIncidenciaPT/${registroId}/${usuarioId}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(startGetPTServicio(id, true));
      dispatch(
        notification(composeSuccessNotification("Registro actualizado"))
      );
    } else {
      dispatch(
        notification(
          composeErrorNotification("TerminarIncidencia", `${result.error}`)
        )
      );
    }
  };
};

///

export const setFiltroRefactura = (valor) => ({
  type: types.setFiltroRefactura,
  payload: valor,
});

export const startGetByFiltroRefactura = (valor) => {
  return async (dispatch) => {
    const url = `${URL_PT}/GetByFiltroRefactura/${valor}`;
    console.log(url);
    const result = await post(url);

    if (result.code === 0) {
      dispatch(storeListadoRefactura(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification("GetByFiltroRefactura", `${result.error}`)
        )
      );
    }
  };
};

const storeListadoRefactura = (val) => ({
  type: types.storeListadoRefactura,
  payload: val,
});

///

export const startLoadIncidenciasResponsable = (responsable) => {
  return async (dispatch) => {
    const url = `${URL_INCIDENCIAS}/GetInicidenciasPTAbiertas/${responsable}`;
    const result = await get(url);

    if (result.code === 0) {
      dispatch(storeIncidenciasAbiertas(result.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "GetInicidenciasPTAbiertas",
            `${result.error}`
          )
        )
      );
    }
  };
};

const storeIncidenciasAbiertas = (incidencias) => ({
  type: types.storeIncidenciasAbiertas,
  payload: incidencias,
});
