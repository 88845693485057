import React, { useState } from "react";
import { Col_12_8_6_4 } from "../../../../components/col/Col_12_8_6_4";
import LabelValor from "../../../../components/label/LabelValor";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { startUpdateServicio } from "../../../../context/actions/concesionariosActions";
import { Input, Modal } from "antd";

export const PersonaConfirmaIngreso = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [data, setNewData] = useState();
  const { concesionariosServicio: c } = useSelector(
    (state) => state.concesionariosReducer
  );

  const HandleOk = () => {
    dispatch(startUpdateServicio({ personaConfirmaIngreso: data }, id));
    setOpen(false);
  };
  return (
    <>
      <Col_12_8_6_4>
        <LabelValor
          labelStyle={{ cursor: "pointer", textDecoration: "underline dotted" }}
          label="Persona confirma ingreso"
          valor={c.personaConfirmaIngreso}
          onClick={() => setOpen(true)}
        />
      </Col_12_8_6_4>
      <Modal
        title="Establecer Persona Confirma Ingreso"
        onCancel={() => setOpen(false)}
        onOk={HandleOk}
        open={open}
      >
        <Input onChange={({ target }) => setNewData(target.value)} />
      </Modal>
    </>
  );
};
